import React, {useState, useEffect} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Badge, {BADGE_TYPES} from '@frontend/ui-kit/Components/Badge';
import Table from '@frontend/ui-kit/Components/Table';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import DistanceChecking from '../../shared/DistanceChecking';
import CollapseInitiator from '../../shared/CollapseInitiator';
import RecommendationsInfoPopup from '../RecommendationsInfoPopup';
import withPopup from '../../../HOC/withPopup';
import {setActiveDecisionTab, setActiveDecisionKind} from '../../../actions/decisionCenter';
import {requestProviderList, requestProvider, setActiveNpi} from '../../../actions/providers';
import {setFormData} from '../../../actions/shared';
import {getProviderList} from '../../../selectors/providers';
import {getFormData} from '../../../selectors/shared';
import CollapsedCellContent from './CollapsedCellContent';
import CopyClip from '../../shared/CopyClip';
import {formatAddress, getProviderLinkCell, generateBaseDecision} from '../../../helpers';
import {
    isEmpty,
    isFinite,
    equal,
    compose,
    getItemKeyValue,
    formatDate,
    parseDateISO,
    getUtcToZonedTime,
    getDateTime
} from '../../../utils';
import {DECISION_FORMS_BY_TYPE, DECISION_KINDS, DECISION_TYPES} from '../../../constants';
import styles from './index.module.scss';

const POPUP_ID = 'providersSearchResultsPopup';
const LOADING_LIMIT = 2;
const PAGE_SIZE = 10;

const getTotalPages = data => Math.ceil(data.length / PAGE_SIZE);

const BADGES_TYPES = [
    {min: 4.5, type: BADGE_TYPES.success},
    {min: 4, type: BADGE_TYPES.secondary},
    {min: 3, type: BADGE_TYPES.warning},
    {min: 0, type: BADGE_TYPES.danger}
];

const ProvidersSearchResults = ({searchingParams, decisionType, openPopup, closePopup}) => {
    const dispatch = useDispatch();
    const [isNpiSearch, setNpiSearch] = useState(false);
    const [searchId, setSearchId] = useState();
    const [providers, setProviders] = useState([]);
    const [isExtraLoadingAvailable, setIsExtraLoadingAvailable] = useState(false);
    const [isThirdpartySearchingAvailable, setIsThirdpartySearchingAvailable] = useState(true);
    const [loadingCount, setLoadingCount] = useState(0);
    const [isLastPage, setIsLastPage] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const providerList = useSelector(getProviderList);

    const formType = DECISION_FORMS_BY_TYPE[decisionType];
    const {decisions, ...restFormData} = useSelector(getFormData(formType));

    const decisionsNpis = !isEmpty(decisions) ? decisions.map(({details}) => details.npi) : [];
    const isLoadingLimitExists = !isNpiSearch && loadingCount < LOADING_LIMIT;

    const isSingleDecision = equal(decisionType, DECISION_TYPES.appointment);

    useEffect(() => {
        (async () => {
            const {npi, zip_code: zipCode, use_ribbon_health: useRibbonHealth, ...restSearchingParams} = searchingParams;
            const {profile_id: profileId} = restSearchingParams;
            const isNpiSearch = !!npi;
            const {provider} = isNpiSearch && await dispatch(requestProvider({npi, profileId}));
            const {providers, isExtraLoadingAvailable, isThirdpartySearchingAvailable, searchId} = !isNpiSearch
                ? await dispatch(requestProviderList({...restSearchingParams, use_ribbon_health: useRibbonHealth, zip: zipCode}))
                : {providers: [], isExtraLoadingAvailable: false, isThirdpartySearchingAvailable: true};
            const searchingProviders = [].concat(provider, ...providers).filter(Boolean);

            const totalPages = getTotalPages(searchingProviders);

            setIsThirdpartySearchingAvailable(isThirdpartySearchingAvailable);
            setProviders(searchingProviders);
            setNpiSearch(isNpiSearch);
            setIsExtraLoadingAvailable(isExtraLoadingAvailable);
            setLoadingCount(0);
            setSearchId(searchId);
            setTotalPages(totalPages);
        })();
    }, [searchingParams, dispatch]);

    const onPageChange = page => {
        const currentPage = page + 1;
        const isLastPage = equal(currentPage, totalPages);

        setIsLastPage(isLastPage);
    };

    const onLoadProviders = async () => {
        const offset = providers.length;
        const {providers: loadedProviders, isExtraLoadingAvailable} = await dispatch(requestProviderList({...searchingParams, offset}));
        const updatedSearchingProviders = [...providers, ...loadedProviders];
        const totalPages = getTotalPages(updatedSearchingProviders);

        setIsExtraLoadingAvailable(isExtraLoadingAvailable);
        setLoadingCount(loadingCount + 1);
        setProviders(updatedSearchingProviders);
        setIsLastPage(false);
        setTotalPages(totalPages);
    };

    const addProviderToDecision = async (npi, searchId) => {
        // FYI: Need both conditions, because Provider can be in list, but data is not fully loaded (Slava, 09.09.2022)
        const {provider} = providerList[npi] && providerList[npi]?.isFullDataLoaded ? {provider: providerList[npi]} : await dispatch(requestProvider({npi, searchId}));
        const {type: providerType, highlight: providerHighlight} = provider;
        const largestOrder = Math.max(...decisions.map(getItemKeyValue('order')));
        const decisionOrder = isFinite(largestOrder) ? largestOrder + 1 : 0;
        const decisionBasicData = generateBaseDecision({decisionType, providerType, npi, providerHighlight, order: decisionOrder});
        const updatedDecisions = isSingleDecision ? [decisionBasicData] : [...decisions, decisionBasicData];
        const updatedFormData = {...restFormData, decisions: updatedDecisions};
        const updatedActiveDecisionTab = isSingleDecision ? 0 : decisions.length;

        dispatch(setActiveNpi(npi));
        dispatch(setActiveDecisionKind(DECISION_KINDS.provider));
        dispatch(setActiveDecisionTab(updatedActiveDecisionTab));
        dispatch(setFormData({[formType]: updatedFormData}));
    };

    const openProviderConfirmationPopup = (npi, providerName) => {
        const popupContent = <Text isInline><Text isInline type={TEXT_TYPES.bodyBold}> {providerName} </Text> has already been recommended to this member earlier</Text>;
        const onAdd = () => {
            addProviderToDecision(npi);
            closePopup();
        };
        const actionBar = (
            <React.Fragment>
                <Button type={BUTTON_TYPES.primary} onClick={onAdd}>Yes</Button>
                <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
            </React.Fragment>
        );
        const popupProps = {title: 'Confirm decision', actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        openPopup({modalType: 'simple', children});
    };

    const onOpenRecommendationsInfoPopup = (npi, formattedlastTimeRecommended, timesRecommended, formatedUpdatedEarliestAvailability, wasRecommendedToUser) => {
        const popupProps = {npi, formattedlastTimeRecommended, timesRecommended, formatedUpdatedEarliestAvailability, wasRecommendedToUser};
        const children = <RecommendationsInfoPopup {...popupProps}/>;

        return openPopup({modalType: 'fullscreen', children});
    };

    const getNetworksCell = ({value}) => {
        const formattedValue = Object.values(value).map(({name}) => name).join(', ');

        return !formattedValue ? '-' : <CollapsedCellContent value={formattedValue}/>;
    };

    const getBadgeType = value => {
        const badgeType = BADGES_TYPES.find(({min}) => value >= min);
        return badgeType ? badgeType.type : BADGE_TYPES.secondary;
    };

    const renderBadgeCell = value => {
        const badgeType = getBadgeType(value);
        const formattedValue = value || '-';
        return <Badge type={badgeType} isBordered className={styles['providers-search-results__badge-wrapper']}>{formattedValue}</Badge>;
    };

    const getScoreCell = ({value}) => renderBadgeCell(value);

    const getOfficesConfidencecell = ({value}) => {
        const {confidence} = value;
        return renderBadgeCell(confidence);
    };

    const getDistanceCell = ({row: {original}}) => {
        const office = isNpiSearch ? {} : original.office;
        const {location} = office ?? {};
        const {lat, lon, ...restLocation} = location ?? {};
        const startPoint = formatAddress(searchingParams);
        const endPoint = lat && lon ? `${lat}, ${lon}` : formatAddress(restLocation);

        return <DistanceChecking startPoint={startPoint} endPoint={endPoint}/>;
    };

    const getPhoneNumberCell = ({row: {original}}) => {
        const office = isNpiSearch ? {} : original.office;
        const {phone_number: phoneNumber} = office ?? {};
        const textToCopy = phoneNumber || '-';

        return <CopyClip textToCopy={textToCopy} tooltipContent='Copied to Clipboard' className={styles['providers-search-results__copy-phone-link']}/>;
    };

    const getTimesRecommendedCell = item => {
        const {value, row: {original}} = item;
        const {
            npi,
            earliest_availability: earliestAvailability,
            last_time_recommended: lastTimeRecommended,
            was_recommended_to_user: wasRecommendedToUser
        } = original;
        const timesRecommended = value;
        const updatedEarliestAvailability = getDateTime(parseDateISO(earliestAvailability));
        const formattedlastTimeRecommended = lastTimeRecommended && formatDate(getUtcToZonedTime(lastTimeRecommended), 'MM/dd/yyyy');
        const formatedUpdatedEarliestAvailability = updatedEarliestAvailability ? formatDate(updatedEarliestAvailability, 'MM/dd/yyyy') : 'N/A';
        return (
            <React.Fragment>
                <div className='pl-5'>
                    Last Recommended: <Text isInline type={TEXT_TYPES.bodyBold}>{formattedlastTimeRecommended || '-'}</Text>
                </div>
                <Text className='pl-5 mt-5'>
                    Recommended to this user:
                    &nbsp;
                    {wasRecommendedToUser && <span className={styles['providers-search-results__was-recommended']}>Yes</span>}
                    {!wasRecommendedToUser && <span className={styles['providers-search-results__was-not-recommended']}>No</span>}
                </Text>

                {timesRecommended > 0 && (
                    <Button type={BUTTON_TYPES.tertiary}
                        onClick={() => onOpenRecommendationsInfoPopup(npi, formattedlastTimeRecommended, timesRecommended, formatedUpdatedEarliestAvailability, wasRecommendedToUser)}>
                        View all records
                        <Icon type={ICON_TYPES.arrowRight}/>
                    </Button>
                )}
            </React.Fragment>
        );
    };

    const getActionsCell = item => {
        const {row: {original}} = item;
        const {npi, is_disabled: isDisabled, was_recommended_to_user: wasRecommendedToUser, name: providerName} = original;
        const isProviderAdded = decisionsNpis.includes(npi);

        return (
            <span>
                {isProviderAdded && (
                    <div className={styles['providers-search-results__colored-action']}>
                        <Icon type={ICON_TYPES.checkCircleFull}/>
                        <Text isInline className={classnames('white-space-normal')}>Added</Text>
                    </div>
                )}

                {!isProviderAdded && (
                    <Button type={BUTTON_TYPES.tertiary}
                        disabled={isDisabled || isProviderAdded}
                        onClick={() => wasRecommendedToUser ? openProviderConfirmationPopup(npi, providerName) : addProviderToDecision(npi, searchId)}>
                        + Add
                    </Button>
                )}
            </span>
        );
    };

    const getNameAndNpiCell = ({row: {original}}) => {
        const {is_disabled: isDisabled, display_name: displayName, is_display_name_enabled: isDisplayNameEnabled, npi, primary_specialty: primarySpecialty} = original;
        const name = isDisplayNameEnabled ? displayName : original.name;

        return (
            <React.Fragment>
                <Text type={TEXT_TYPES.bodyBold} className={classnames('white-space-normal', {[styles['providers-search-results__disabled-provider']]: isDisabled})}>{name}</Text>
                <div className='mt-10'>
                    NPI: {getProviderLinkCell({value: npi, searchId})}
                </div>
                <div className='mt-10 white-space-pre-wrap'>
                    Specialty: {primarySpecialty || '-'}
                </div>
            </React.Fragment>
        );
    };

    const tableColumns = [
        {Header: 'Provider', accessor: 'provider', Cell: getNameAndNpiCell},
        {Header: 'Phone', accessor: 'phone_number', width: 110, Cell: getPhoneNumberCell},
        {Header: 'Networks', accessor: 'networks', width: 110, Cell: getNetworksCell},
        {Header: 'Notes', accessor: 'decision_count', Cell: getTimesRecommendedCell},
        {Header: <Tooltip content='Cost Efficiency'><Icon type={ICON_TYPES.moneyCouple}/></Tooltip>, accessor: 'cost_efficiency_score', width: 65, Cell: getScoreCell},
        {Header: <Tooltip content='Clinical Outcomes'><Icon type={ICON_TYPES.care}/></Tooltip>, accessor: 'clinical_outcomes_score', width: 65, Cell: getScoreCell},
        {Header: <Tooltip content='Patient Ratings'><Icon type={ICON_TYPES.starFull}/></Tooltip>, accessor: 'rating', width: 65, Cell: getScoreCell},
        {Header: <Tooltip content='Confidence Score'><Icon type={ICON_TYPES.thumbsUp}/></Tooltip>, accessor: 'offices[0]', width: 65, Cell: getOfficesConfidencecell},
        {Header: 'Distance', accessor: 'offices', width: 90, Cell: getDistanceCell},
        {Header: 'Actions', width: 90, Cell: getActionsCell}
    ];

    const tableProps = {
        className: styles['providers-search-results-table'],
        data: providers,
        columns: tableColumns,
        unit: 'provider',
        isFilterable: false,
        isSortable: false,
        pageSize: PAGE_SIZE,
        onPageChange
    };

    return (
        <React.Fragment>
            {!isThirdpartySearchingAvailable && (
                <Alert className='mb-15' type={ALERT_TYPES.danger} description='Third Party Searching is unavailable right now, search results are limited to HealthJoy records'/>
            )}
            <Collapse isOpened
                className={styles['providers-search-results-collapse']}
                content={<Table {...tableProps}/>}
                initiator={<CollapseInitiator title='Providers search results'/>}
                hasCollapseIcon/>

            {isExtraLoadingAvailable && isLoadingLimitExists && isLastPage && (
                <Row center='sm'>
                    <Column sm={2}>
                        <Button type={BUTTON_TYPES.secondary} onClick={onLoadProviders}>Load more results</Button>
                    </Column>
                </Row>
            )}
        </React.Fragment>
    );
};

ProvidersSearchResults.propTypes = {
    searchingParams: PropTypes.shape({
        npi: PropTypes.string,
        profile_id: PropTypes.number,
        zip_code: PropTypes.string,
        state: PropTypes.string,
        city: PropTypes.string,
        street_address: PropTypes.string,
        use_ribbon_health: PropTypes.bool
    }),
    decisionType: PropTypes.string,
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {ProvidersSearchResults as TestableProvidersSearchResults};
export default compose(
    React.memo,
    withPopup(POPUP_ID)
)(ProvidersSearchResults);
