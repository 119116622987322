import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import classnames from 'classnames';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Separator from '@frontend/ui-kit/Components/Separator';
import Link from '@frontend/ui-kit/Components/Link';
import {getIntakeInfo, getCustomerNetworkName} from '../../../selectors/decisionCenter';
import {getProviderByNpi} from '../../../selectors/providers';
import {getOfficeName, isInternalNpi} from '../../../helpers';
import {isEmpty, equal} from '../../../utils';
import {LANGUAGE_NAMES, DECISION_TYPES} from '../../../constants';

const REQUEST_TYPE_TEXT = {
    'select-provider': 'Help selecting a provider',
    'establish-care': 'Establish care',
    'specific-condition': 'Treatment of a specific condition',
    physical: 'Physical discomfort or pain',
    other: 'Other'
};

const INTAKE_QUESTIONS = {
    'in-network': 'Verify the provider is in-network',
    'accepting-new-patients': 'Check to see if the provider is accepting new patients',
    'visit-cost': 'Determine how much my visit/procedure will cost',
    'schedule-appointment': 'Schedule an appointment for me',
    'appointment-questions': 'Appointment follow up questions',
    'specific-service-provided': 'Check if a specific service/procedure can be provided',
    'establish-care': 'Establish care as a new patient',
    'address-specific': 'Address a specific condition',
    'address-discomfort-pain': 'Address discomfort or pain',
    'procedure-or-service': 'Perform a procedure or service',
    other: 'Other'
};

const getFilteredOfficeById = officeIds => ({id}) => officeIds.includes(id);

const getOffice = (office, index) => <li key={index}><Text type={TEXT_TYPES.bodyBold}>{office}</Text></li>;

const getQuestion = (question, index) => <li key={index}><Text type={TEXT_TYPES.bodyBold}>{INTAKE_QUESTIONS[question] ?? question}</Text></li>;

const IntakeInfo = ({decisionType, className}) => {
    const {
        office_id: officeId,
        office_ids: officeIds,
        additional_info: additionalInfo,
        suggest_alternative: suggestAlternative,
        search_id: searchId,
        questions,
        npi,
        reason_for_visit: reasonForVisit,
        appointment_preferences: appointmentPreferences,
        other_appointment_preferences: otherAppointmentPreferences,
        selected_days: selectedDays,
        first_availability: firstAvailability,
        parent_dc_link: parentDCLink,
        OTRSTicketLink,
        locale,
        request_type: requestType
    } = useSelector(getIntakeInfo);
    const customerNetworkName = useSelector(getCustomerNetworkName);
    const {offices = []} = useSelector(getProviderByNpi(npi));
    const journeyLanguage = LANGUAGE_NAMES[locale];
    const isAlternativeSuggest = equal(decisionType, DECISION_TYPES.provider);
    const selectedOfficeIds = [officeId, ...officeIds].filter(Boolean);
    const selectedOffices = offices.filter(getFilteredOfficeById(selectedOfficeIds)).map(getOfficeName);

    return (
        <ContentSection className={classnames(className)}>
            {!OTRSTicketLink && (
                <Alert className='mb-10'
                    type={ALERT_TYPES.danger}
                    description='Something went wrong at ticket creation, please recreate the ticket manually from CRM to make sure it is linked to OTRS'/>
            )}

            <Heading className='mb-10' type={HEADING_TYPES['4']}>
                Intake information
            </Heading>

            {OTRSTicketLink && (
                <Text data-testid='otrs-ticket-link' className='mb-5'>
                    Ticket URL:&nbsp;
                    <Link href={OTRSTicketLink} target='_blank'>
                        {OTRSTicketLink}
                    </Link>
                </Text>
            )}

            {customerNetworkName && (
                <Text data-testid='customer-network-name' className='mb-5'>
                    Network Name: <Text type={TEXT_TYPES.bodyBold} isInline>{customerNetworkName}</Text>
                </Text>
            )}

            {locale && (
                <Text data-testid='intake-info-locale'>
                    Journey language: <Text type={TEXT_TYPES.bodyBold} isInline>{journeyLanguage}</Text>
                </Text>
            )}

            <Separator/>

            {!isEmpty(questions) && (
                <div data-testid='intake-info-questions' className='mb-6'>
                    <Text className='mb-6'>Questions:</Text>
                    <ul className='ml-10'>{questions.map(getQuestion)}</ul>
                </div>
            )}

            {additionalInfo && (
                <Text data-testid='intake-info-additional-info' className='mb-5'>
                    Additional info: <Text isInline type={TEXT_TYPES.bodyBold}>{additionalInfo}</Text>
                </Text>
            )}

            {requestType && (
                <Text data-testid='intake-info-request-type' className='mb-5'>
                    Request type: <Text isInline type={TEXT_TYPES.bodyBold}>{REQUEST_TYPE_TEXT[requestType] ?? requestType}</Text>
                </Text>
            )}

            {searchId && (
                <Text data-testid='intake-info-search-id' className='mb-5'>
                    Search ID: <Text isInline type={TEXT_TYPES.bodyBold}>{searchId}</Text>
                </Text>
            )}

            {!isEmpty(selectedOffices) && (
                <div data-testid='intake-info-selected-offices' className='mb-5'>
                    <Text className='mb-6'>Selected offices:</Text>
                    <ul className='ml-10'>{selectedOffices.map(getOffice)}</ul>
                </div>
            )}

            {npi && (
                <Text data-testid='intake-info-npi' className='mb-5'>
                    {isInternalNpi(npi) ? 'Internal ID' : 'NPI'}: <Text isInline type={TEXT_TYPES.bodyBold}>{npi}</Text>
                </Text>
            )}

            {isAlternativeSuggest && (
                <Text data-testid='intake-info-alternative-suggestion' className='mb-5'>
                    Suggest alternative: <Text isInline type={TEXT_TYPES.bodyBold}>{suggestAlternative ? 'Yes' : 'No'}</Text>
                </Text>
            )}

            {reasonForVisit && (
                <Text data-testid='intake-info-reason-for-visit' className='mb-5'>
                    Reason for visit: <Text isInline type={TEXT_TYPES.bodyBold}>{reasonForVisit}</Text>
                </Text>
            )}

            {appointmentPreferences && (
                <Text data-testid='intake-info-appointment-preferences' className='mb-5'>
                    Appointment preferences: <Text isInline type={TEXT_TYPES.bodyBold}>{appointmentPreferences}</Text>
                </Text>
            )}

            {otherAppointmentPreferences && (
                <Text data-testid='intake-info-other-appointment-preferences' className='mb-5'>
                    Other appointment preferences: <Text isInline type={TEXT_TYPES.bodyBold}>{otherAppointmentPreferences}</Text>
                </Text>
            )}

            {selectedDays && (
                <Text data-testid='intake-info-selected-days' className='mb-5'>
                    Selected days: <Text isInline type={TEXT_TYPES.bodyBold}>{selectedDays}</Text>
                </Text>
            )}

            {firstAvailability && (
                <Text data-testid='intake-info-first-availability' className='mb-5'>
                    First availability: <Text isInline type={TEXT_TYPES.bodyBold}>{firstAvailability}</Text>
                </Text>
            )}

            {parentDCLink && (
                <Text data-testid='intake-info-parent-dc-link' className='mb-5'>
                    Parent DC link: <Link href={parentDCLink} target='_blank'>{parentDCLink}</Link>
                </Text>
            )}
        </ContentSection>
    );
};

IntakeInfo.propTypes = {
    decisionType: PropTypes.string,
    className: PropTypes.string
};

IntakeInfo.defaultProps = {
    className: ''
};

export {IntakeInfo as TestableIntakeInfo};
export default React.memo(IntakeInfo);
